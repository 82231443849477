import React, { Suspense } from 'react';
import { QueryClientProvider } from 'react-query';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';

import { queryClient, USE_ADMIN, USE_APP } from 'config';
import { styles } from '../../styles';

const GlobalStyle = createGlobalStyle`
  body {
    color: ${styles.color.text};
  }
`;

const AdminView = React.lazy(() => import('../admin/index.js'));
const LPView = React.lazy(() => import('../lp/index.js'));

const Router = () => {
  return (
    <Switch>
      {USE_ADMIN && (
        <Route path="/admin">
          <AdminView />
        </Route>
      )}

      {USE_APP && (
        <Route path="/">
          <LPView />
        </Route>
      )}
      {USE_APP && <Redirect to="/" />}
      {USE_ADMIN && <Redirect to="/admin" />}
    </Switch>
  );
};

export const RootApp = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <>
          <GlobalStyle />
          <Suspense fallback={<div />}>
            <Router />
          </Suspense>
        </>
      </BrowserRouter>
    </QueryClientProvider>
  );
};
