import { keyBy } from 'lodash';
import { QueryClient } from 'react-query';

export const API_URL = process.env.REACT_APP_API_URL;
export const USE_ADMIN = process.env.REACT_APP_USE_ADMIN === 'true';
export const USE_APP = process.env.REACT_APP_USE_APP === 'true';

export const queryClient = new QueryClient();

export const wrapJsonFetch = async (f) => {
  const r = await f;
  if (r.status === 200) {
    return r.json();
  }
  throw new Error(`${r.status}: ${await r.text()}`);
};

export const CUSTOM_COLUMNS = keyBy(
  [
    {
      id: 'created_at',
      name: 'Date Added',
    },
    {
      id: 'last_note',
      name: 'Note',
    },
    {
      id: 'last_interaction',
      name: 'Last Contact',
    },
  ],
  'id'
);
export const NOTE_COLUMN = {
  id: 'note',
  name: 'Note',
};

export const CUSTOM_COLUMNS_TYPE = {
  last_interaction: 'last_interaction',
};

export const DATE_ADDED = 'Date Added';
