export const styles = {
  spaces: {
    sm: '4px',
    md: '8px',
    lg: '14px',
  },
  shadow: {
    light: `1px 1px 6px #dcdcdc`,
    base: `0 0 4px 3px rgba(0, 0, 0, 0.1)`,
  },
  borderRadius: {
    md: '2px',
    lg: '12px',
  },

  fontSize: {
    sm: '12px',
    smd: '14px',
    md: '16px',
  },

  color: {
    primary: '#F42C00',
    primaryLight: '#FACABB',
    border: '#E5E7EB',
    borderLight: '#F3F4F6',
    veryLight: '#F9FAFB',

    blueVeryLight: '#EFF6FF',
    blueLight: '#DBEAFE',

    lightHighlight: '#D97706',
    yellowHighlight: '#FEF3C7',
    yellowLight: '#FFFBEB',

    danger: '#EF4444',
    success: '#10B981',

    text: 'rgb(85, 85, 85)',
    textLight: 'rgba(125,125,125)',
  },

  zIndex: {
    cellTooltips: 10000,
    columnFilter: 99999,
    requestMoreInfo: 99999,
  },
};
